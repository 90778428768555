import React from "react";


import home1 from "../../../assets/img/bed-cover1.webp";
import home2 from "../../../assets/img/bed-cover2.webp";

import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="aboutImg">
                <img src={home1} alt="About" className="img-fluid" />
                <img src={home2} alt="About" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-5">
              <div className="aboutWooden">
                <span>Best Collection</span>
                <h2>Queen Bed Covers</h2>
                <p>
                Established in the year 2000, Worldwide Exports is a renowned organization known for manufacturing, wholesaling and exporting Handicrafts and Home Furnishing products. Our range of products comprises Bed Cover, Curtain, Cushion Covers, Dollies, Fashion Items, Pouch, Runner, Table Cover and Tissue Box Cover. Manufactured using high quality fabric, these are available in various styles, patterns and designs.



                </p>
                <Link to={"/"}>shop now</Link>
              </div>
            </div>
            
          </div>
          
        </div>
      </section>
    </>
  );
}

export default ServiceList;
