import React from "react";
import { useTranslation } from "react-i18next";
import { RiCustomerService2Line, RiProductHuntLine } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";

function Network() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="networkSec">
        <div className="container">
          <div className="networkItem">
            <div className="fisherman-content">
              <h3>{t("Our Network")} </h3>
            </div>
            <ul className="namedd">
              <li>
                <div className="networkNumber">
                  <RiCustomerService2Line />
                  <h2>40 Thousands +</h2>
                  <p>{t("Customers")}</p>
                </div>
              </li>
              <li>
                <div className="networkNumber">
                <RiProductHuntLine />

                  <h2>1 Thousands +</h2>
                  <p>{t("Products")}</p>
                </div>
              </li>
              <li>
                <div className="networkNumber">
                  <TbTruckDelivery />
                  <h2>500 Hundred +</h2>
                  <p>{t("Delivery Depot")}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Network;
