import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Blog from "../../components/blog/Blog";

function BlogPage({ setShow }) {
  return (
    <>
      <Helmet>
        <title>Blog | World Wide Exports</title>
        <meta name="keyword" content="World Wide Exports" />
        <meta name="description" content="World Wide Exports" />
      </Helmet>
      <Blog />
    </>
  );
}

export default BlogPage;
