import React from "react";
import { Link } from "react-router-dom";
import image1 from "../../../assets/img/blog-11.webp";
import image2 from "../../../assets/img/blog-10.webp";
import image3 from "../../../assets/img/blog-9.webp";

const LatestNews = () => {
  return (
    <>
      <section className="LatestSec">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="latestNewCard">
                <div className="latestNewsImage">
                  <img src={image1} alt="image1" className="img-fluid" />
                </div>
                <div className="latestNewsText">
                  <h6>21 - May - 2022</h6>
                  <h3>Unique Covers for your Home</h3>
                  <Link to={"/"}>know more</Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="latestNewCard">
               
                <div className="latestNewsText">
                  <h6>21 - May - 2022</h6>
                  <h3>Luxury Room with Coverss</h3>
                  
                  <Link to={"/"}>know more</Link>
                </div>
                <div className="latestNewsImage">
                  <img src={image2} alt="image1" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="latestNewCard">
                <div className="latestNewsImage">
                  <img src={image3} alt="image1" className="img-fluid" />
                </div>
                <div className="latestNewsText">
                  <h6>21 - May - 2022</h6>
                  <h3>Premium Coverss for Office</h3>
                  <Link to={"/"}>know more</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestNews;
